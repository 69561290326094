import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home"; // Import Home component
import VideoServer from "./VideoServer"; // Import VideoServer component

const LiveChat = lazy(() => import("./LiveChat")); // Lazy load LiveChat component
const AstroChat = lazy(() => import("./AstroChat")); // Lazy load AstroChat component
const CallServer = lazy(() => import("./CallServer")); // Lazy load CallServer component
const LiveServer = lazy(() => import("./LiveServer")); // Lazy load LiveServer component

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/livechat" element={<LiveChat />} />
            <Route path="/astrochat" element={<AstroChat />} />
            <Route path="/videoserver" element={<VideoServer />} /> {/* Add VideoServer route */}
            <Route path="/callserver" element={<CallServer />} /> {/* Add CallServer route */}
            <Route path="/liveserver" element={<LiveServer />} /> {/* Add LiveServer route */}
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;