import React, { useState, useEffect } from "react";
import { io } from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './VideoCall.css'; // Create and import a CSS file for VideoCall component

// Initialize socket connection with reconnection options
const socket = io(process.env.REACT_APP_API_URL_VIDEO_SERVER, {
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 20000,
});

function VideoCall() {
  const [room, setRoom] = useState(""); // State for room ID
  const [isRoomJoined, setIsRoomJoined] = useState(false); // State for checking if the room is joined
  const [isLoading, setIsLoading] = useState(false); // State for loading status
  const [timestamp, setTimestamp] = useState(""); // State for input timestamp
  const [receivedTimestamp, setReceivedTimestamp] = useState(""); // State for received timestamp

  // Function to join a room
  const joinRoom = () => {
    if (room.trim() !== "") {
      socket.emit("join_room", { room });
      setIsRoomJoined(true);
      toast.success(`Joined room: ${room}`);
    } else {
      toast.error("Please enter a room ID");
    }
  };

  // Function to send the input timestamp
  const sendTimestamp = () => {
    if (!isRoomJoined) {
      toast.error("Please join a room first");
      return;
    }

    if (timestamp.trim() === "") {
      toast.error("Please enter a timestamp");
      return;
    }

    socket.emit("send_timestamp", { room, timestamp });
    toast.success("Timestamp sent!");
  };

  // Function to request the current timestamp
  const requestTimestamp = () => {
    if (!isRoomJoined) {
      toast.error("Please join a room first");
      return;
    }

    socket.emit("request_timestamp", { room });
  };

  // Handle receiving timestamp
  useEffect(() => {
    const handleReceiveTimestamp = ({ timestamp }) => {
      setReceivedTimestamp(timestamp);
      toast.info(`Received timestamp: ${timestamp}`);
    };

    socket.on("receive_timestamp", handleReceiveTimestamp);

    return () => {
      socket.off("receive_timestamp", handleReceiveTimestamp);
    };
  }, []);

  return (
    <div className="VideoCall">
      <header className="header">
        <h1>Video Call Room</h1>
      </header>
      <ToastContainer /> {/* Add ToastContainer */}
      {!isRoomJoined ? (
        <div className="join-room">
          <input
            placeholder="Enter Room Number..."
            onChange={(event) => setRoom(event.target.value)}
            disabled={isLoading}
          />
          <button onClick={joinRoom} disabled={isLoading}>Join Room</button>
        </div>
      ) : (
        <div className="controls">
          <h2>Room: {room}</h2>
          <input
            type="text"
            placeholder="Enter Timestamp..."
            value={timestamp}
            onChange={(event) => setTimestamp(event.target.value)}
            disabled={isLoading}
          />
          <button onClick={sendTimestamp} disabled={isLoading}>
            {isLoading ? 'Sending...' : 'Send Timestamp'}
          </button>
          <button onClick={requestTimestamp} disabled={isLoading}>
            {isLoading ? 'Requesting...' : 'Request Timestamp'}
          </button>
          {receivedTimestamp && <p>Latest Timestamp: {receivedTimestamp}</p>}
        </div>
      )}
      <footer className="footer">
        <p>© 2024 Talk nd Heal | Powered By Immortal Varta</p>
      </footer>
    </div>
  );
}

export default VideoCall;