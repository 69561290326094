import React, { useEffect, useState, useRef } from "react";
import { io } from "socket.io-client";
import { v4 as uuidv4 } from 'uuid'; // Import UUID library
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import './LiveChat.css'; // Import LiveChat CSS
import logo from './image.png'; // Import logo image

// Default image URL
const defaultImageURL = "https://cdn-icons-png.flaticon.com/512/149/149071.png";

// Initialize socket connection
const socket = io(process.env.REACT_APP_API_URL_LIVE_CHAT);

function LiveChat() {
  const [room, setRoom] = useState(""); // State for room ID
  const [message, setMessage] = useState(""); // State for message input
  const [messagesReceived, setMessagesReceived] = useState([]); // State for received messages
  const [isRoomJoined, setIsRoomJoined] = useState(false); // State to check if room is joined
  const [isLoading, setIsLoading] = useState(false); // State for loading status
  const [processedMessages, setProcessedMessages] = useState(new Set()); // Track processed messages
  const [userName, setUserName] = useState(""); // State for user name
  const [userImage, setUserImage] = useState(defaultImageURL); // State for user image URL with default value
  const [timestamp, setTimestamp] = useState(""); // State for input timestamp
  const [receivedTimestamp, setReceivedTimestamp] = useState(""); // State for received timestamp

  const messagesEndRef = useRef(null); // Create a ref for the messages container

  // Function to join a room
  const joinRoom = () => {
    if (room.trim() !== "" && userName.trim() !== "") {
      socket.emit("join_room", { room, name: userName, image: userImage });
      setIsRoomJoined(true);
      toast.success(`Joined room: ${room}`);
    } else {
      toast.error("Please enter a room id and name");
    }
  };

  // Function to send a text message
  const sendTextMessage = () => {
    if (!isRoomJoined) {
      toast.error("Please join a room first");
      return;
    }

    if (message.trim() === "") {
      toast.error("Cannot send an empty message");
      return;
    }

    const currentTimestamp = new Date().toISOString();
    const messageData = {
      type: 'text', // Message type
      content: {
        message,
        room,
        name: userName, // Add user name
        image: userImage, // Add user image
        type: "text",
        send_timestamp: currentTimestamp // Add send timestamp
      }
    };
    try {
      socket.emit("send_text_message", messageData);
      setMessagesReceived(prevMessages => [...prevMessages, { ...messageData.content, type: 'text', sent: true }]);
      setMessage("");
    } catch (error) {
      console.error('Error sending text message:', error);
      toast.error('Failed to send text message. Please try again.');
    }
  };

  // Function to send the input timestamp
  const sendTimestamp = () => {
    if (!isRoomJoined) {
      toast.error("Please join a room first");
      return;
    }

    if (timestamp.trim() === "") {
      toast.error("Please enter a timestamp");
      return;
    }

    socket.emit("send_timestamp", { room, timestamp });
    toast.success("Timestamp sent!");
  };

  // Function to request the current timestamp
  const requestTimestamp = () => {
    if (!isRoomJoined) {
      toast.error("Please join a room first");
      return;
    }

    socket.emit("request_timestamp", { room });
  };

  // Handle incoming messages
  useEffect(() => {
    const handleMessage = (data) => {
      console.log("Received message:", data); // Log received message
      // Check if the message's UUID has already been processed
      if (!processedMessages.has(data.id)) {
        // Update the messagesReceived state with the new incoming message
        setMessagesReceived(prevMessages => [...prevMessages, { ...data, sent: false }]);
        // Add the message's UUID to the set of processed messages
        setProcessedMessages(prevProcessedMessages => new Set(prevProcessedMessages).add(data.id));
      }
    };

    socket.on("receive_message", handleMessage);

    return () => {
      socket.off("receive_message", handleMessage);
    };
  }, [processedMessages]);

  // Handle previous messages
  useEffect(() => {
    const handlePreviousMessages = (data) => {
      console.log("Received previous messages:", data); // Log previous messages
      setMessagesReceived(data);
    };

    socket.on("previous_messages", handlePreviousMessages);

    return () => {
      socket.off("previous_messages", handlePreviousMessages);
    };
  }, []);

  // Handle receiving timestamp
  useEffect(() => {
    const handleReceiveTimestamp = ({ timestamp }) => {
      setReceivedTimestamp(timestamp);
      toast.info(`Received timestamp: ${timestamp}`);
    };

    socket.on("receive_timestamp", handleReceiveTimestamp);

    return () => {
      socket.off("receive_timestamp", handleReceiveTimestamp);
    };
  }, []);

  // Scroll to the bottom of the messages container when messagesReceived changes
  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100); // Adjust the delay as needed
    }
  }, [messagesReceived]);

  // Handle Enter key press to send text message
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      sendTextMessage();
    }
  };

  return (
    <div className="LiveChat">
      <header className="header">
        <div className="logo-container">
          <img
            src={logo}
            alt="Logo"
          />
        </div>
      </header>
      <ToastContainer /> {/* Add ToastContainer */}
      {!isRoomJoined && (
        <div className="join-room">
          <input
            placeholder="Room Number..."
            onChange={(event) => setRoom(event.target.value)}
            disabled={isLoading}
          />
          <input
            placeholder="Your Name..."
            onChange={(event) => setUserName(event.target.value)}
            disabled={isLoading}
          />
          <input
            placeholder="Your Image URL..."
            onChange={(event) => setUserImage(event.target.value || defaultImageURL)}
            disabled={isLoading}
          />
          <button onClick={joinRoom} disabled={isLoading}>Join Room</button>
        </div>
      )}
      {isRoomJoined && (
        <>
          <div className="messages-container">
            <h1>Welcome to the Live Chat Room Broadcast: {room}</h1>
            <ul className="messages">
              {messagesReceived.map((msg, index) => (
                <li key={index} className={`message ${msg.sent ? "sent" : "received"}`}>
                  <div className="message-content">
                    {msg.type === 'text' && (
                      <div className="text-message">
                        <img src={msg.image} alt="Profile" className="profile-image" />
                        <div className="message-text">
                          <p className="user-name">{msg.name}</p>
                          <p>{msg.message}</p>
                          {/* <p className="timestamp">Sent at: {msg.send_timestamp}</p> */}
                        </div>
                      </div>
                    )}
                    {msg.type === 'notification' && (
                      <div className="notification">
                        <img src={msg.image} alt="Profile" className="profile-image" />
                        <div className="notification-text">
                          <p className="user-name">{msg.name}</p>
                          <p className="joined-text">Joined!</p>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              ))}
              <div ref={messagesEndRef} /> {/* Add a div to scroll into view */}
            </ul>
          </div>
          <div className="chat-inputs">
            <div className="input-group">
              <input
                placeholder="Message..."
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                onKeyPress={handleKeyPress}
                disabled={isLoading}
              />
              <button onClick={sendTextMessage} disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Enter Timestamp..."
                value={timestamp}
                onChange={(event) => setTimestamp(event.target.value)}
                disabled={isLoading}
              />
              <button onClick={sendTimestamp} disabled={isLoading}>
                {isLoading ? 'Sending...' : 'Send Timestamp'}
              </button>
              <button onClick={requestTimestamp} disabled={isLoading}>
                {isLoading ? 'Requesting...' : 'Request Timestamp'}
              </button>
              {receivedTimestamp && <p>Latest Timestamp: {receivedTimestamp}</p>}
            </div>
          </div>
        </>
      )}
      <footer className="footer">
        <p>© All copyrights reserved 2024 Talk nd Heal | Powered By Immortal Varta</p>
      </footer>
    </div>
  );
}

export default LiveChat;